import { NextPage, NextPageContext } from 'next'
import ErrorComponent from '@core/components/Error'
import { BaseError } from '@core/utils/error/BaseError'
import { logging } from '@core/utils/logging'

interface IInitialProps {
  statusCode?: number | null
  err?:
    | (Error & {
        statusCode?: number
      })
    | null
}

class SsrRenderError extends BaseError {
  getLevel() {
    return 'fatal '
  }
}

const ErrorPage: NextPage<IInitialProps> = ({ statusCode, err }) => {
  if (statusCode !== 404) {
    // since we are catching all other errors in the withErrorBoundary
    // the only time this is triggered is with SSR render errors
    const error = err?.stack
      ? err
      : new SsrRenderError(
          `Unhandled SSR render error in _error.tsx${
            statusCode ? ` [${statusCode}]` : ''
          }`
        )

    logging.error('SSR failed with fallback to default error page', error)
  }

  return <ErrorComponent statusCode={statusCode} />
}

ErrorPage.getInitialProps = async ({ res, err }: NextPageContext) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : null

  return {
    statusCode,
    err,
  }
}

export default ErrorPage
